.walletContainer {
    display: grid;
    grid-template-areas: 
        "runOnRadix infiniteLogo connectButton"
        ". sendAssetInput ."
        ". headerText ."
        ". assetsHeader ."
        ". assetDisplay .";
    grid-template-columns: 200px 60vw 200px;
    grid-template-rows: auto auto auto;
    align-content: space-evenly;
}
/*
.walletContainer {
    display: grid;
    grid-template-areas: 
        ". infiniteHeader ."
        ". infiniteText .";
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto;
    align-content: space-evenly;
}
*/
.infiniteHeader {
    grid-area: infiniteHeader;
}

.radixSignInContainer {
    grid-area: infiniteText;
}

.logoContainer {
    grid-area: infiniteLogo;
    align-items: center;
    max-width: 100%;
    height: auto;
}

.headerContainer {
    grid-area: headerText;
}

.tradeContainer {
    align-items: center;
    justify-self: center;
}

.rdtButton {
    grid-area: connectButton;
    padding-right: 2rem;
    padding-top: 2rem;
}

.logoContainer, .headerContainer,.rdtButton {
    align-items: center;
    justify-self: center;
}

.buttonContainer, .headerContainer, .assetsContainer {
    color: rgb(148, 204, 214);
    font-family: 'IBM Plex Sans', sans-serif;
}

.sendAssetContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    margin-left: 30vw;
    margin-right: 30vw;
    margin-bottom: 10vh;
}

.sendAssetsInputContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    margin-left: 20vw;
}

.sendFunctionContainer {
    display: flex;
    flex-direction: column;
    margin: 0 5vw;
}

.sendAssetInput {
    display: flex;
    flex-direction: row;
}

.receiverContainer, .assetToSendContainer, .buttonStyles {
    color: rgb(148, 204, 214);
    font-family: 'IBM Plex Sans', sans-serif;
}

.inputContainer:active {
    color: rgb(148, 204, 214);
    font-family: 'IBM Plex Sans', sans-serif;
    border-color: rgb(148, 204, 214);
}

.inputContainer {
    background: transparent;
    color: rgb(148, 204, 214);
    font-family: 'IBM Plex Sans', sans-serif;
    border-color: rgb(148, 204, 214);
    height: 30px;
    border-radius: 5px;
}

.buttonStyles {
    margin-top: 10px;
    width: 75px;
    height: 30px;
    background: transparent;
    border-color: rgb(148, 204, 214);
    font-size: medium;
}

.runsOnRadixContainer {
    grid-area: runOnRadix;
    place-self: left;
    padding-left: 2rem;
    padding-top: 2rem;
}

.assetsContainer {
    grid-area: assetDisplay;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.assetsHeader {
    grid-area: assetsHeader;
    color: white;
    text-align: center;
}

.yourAssetHeaderContainer {
    margin-left: 45%;
}

.nftCardStyleContainer {
    display: flex;
    flex-direction: row;
    justify-items: center;
    margin-left: 10%;
}

.nftCardContainer {
    border: 3px solid white;
    background-color: rgba(9,10,11,1);
    width: 10vw;
    height: 35vh;
    margin: 1rem 0.5rem;
    color: rgb(148, 204, 214);
}

.nftNameContainer, .nftCollectionContainer {
    padding: 0 0.1rem;
    text-align: center;
}

@media only screen and (min-width: 1201px) {
    .nftCardContainer {
        height: 30vh;
        width: 10vw;
        font-size: 15px;
    }
    .nftLogoContainer {
      background-color: lightblue;
      height: 60%;
      width: 100%;
    }
}

@media only screen 
    and (max-width: 1200px) 
    and (min-width: 821px) {
    .nftCardContainer {
        height: 30vh;
        width: 15vw;
        font-size: 14px;
    }
    .nftLogoContainer {
      background-color: lightblue;
      height: 60%;
      width: 100%;
    }
}

@media only screen 
    and (max-width: 820px) 
    and (min-width: 621px) {
    .nftCardContainer {
        height: 25vh;
        width: 15vw;
        font-size: 12px;
    }
    .nftLogoContainer {
      background-color: lightblue;
      height: 60%;
      width: 100%;
    }
}

@media only screen 
    and (max-width: 620px) 
    and (min-width: 381px) {
        .nftCardContainer {
            height: 20vh;
            width: 15vw;
            font-size: 10px;
        }
        .nftLogoContainer {
          background-color: lightblue;
          height: 51%;
          width: 100%;
        }
}

@media only screen and (max-width: 380px) {
    .nftCardContainer {
        height: 22vh;
        width: 15vw;
        font-size: 8px;
    }
    .nftLogoContainer {
      background-color: lightblue;
      height: 50%;
      width: 100%;
    }
  }

